var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', [_c('v-card-title', {
    staticClass: "align-start title"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.currentChart.nicename) + " "), _vm.currentDistributor ? _c('v-chip', [_vm._v(" " + _vm._s(_vm.currentDistributor.nicename) + " ")]) : _vm._e()], 1), _c('v-spacer'), _c('v-btn', {
    staticClass: "mt-n2 me-n3",
    attrs: {
      "icon": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.managing = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "22"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiDotsVertical) + " ")])], 1), _vm.managing ? _c('ChartOptionAside', {
    attrs: {
      "active": true,
      "resource": _vm.currentChart
    },
    on: {
      "aside": function aside(bool) {
        if (!bool) {
          _vm.managing = false;
        }
      },
      "submit": _vm.handleOptionUpdate,
      "remove": function remove($event) {
        return _vm.$emit('remove');
      }
    }
  }) : _vm._e()], 1), _vm.results ? _c('v-card-text', [_vm._l(Object.entries(_vm.results.analysis), function (_ref) {
    var key = _ref[0],
      value = _ref[1];
    return _c('span', {
      key: key,
      staticClass: "mr-4"
    }, [_vm._v(" " + _vm._s(key) + ": " + _vm._s(_vm.formatByKey(value, key)) + " ")]);
  }), _vm.data.length && _vm.currentChart.type === 'interval-analysis' ? _c('BarChart', {
    attrs: {
      "data": _vm.data,
      "labels": _vm.labels,
      "formatter": _vm.formatter
    }
  }) : _vm._e(), _vm.data.length && _vm.currentChart.type === 'rank-analysis' ? _c('TableChart', {
    attrs: {
      "data": _vm.data,
      "labels": _vm.labels,
      "formatter": _vm.formatter
    }
  }) : _vm._e()], 2) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }