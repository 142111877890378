<template>
  <div>
    <v-row
      v-for="(label, index) in labels"
      :key="label"
    >
      <v-col cols="10">
        {{ label }}
      </v-col>
      <v-col cols="2">
        {{ data[index] }}
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {

  props: {
    data: {
      type: Array,
      default: () => [40, 60, 50, 60, 75, 60, 10],
    },
    labels: {
      type: Array,
      default: () => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
  },

  setup(props) {
    return {
    }
  },
}
</script>

  <style scoped>
  </style>
