var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.ready ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h1', [_vm._v("Welcome to MixCare Wellness Admin Portal")])]), _vm._l(_vm.sortedOptions, function (itemOption, index) {
    return _c('v-col', {
      key: "".concat(itemOption.nicename, "_").concat(itemOption.sort),
      attrs: {
        "cols": itemOption.cols
      }
    }, [_c('DashboardItem', {
      attrs: {
        "index": index,
        "chart": itemOption
      },
      on: {
        "submit": function submit(form) {
          return _vm.handleUpdates(form, index);
        },
        "remove": function remove() {
          return _vm.handleRemove(index);
        }
      }
    })], 1);
  }), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('a', {
    on: {
      "click": _vm.addChart
    }
  }, [_vm._v("Add Chart +")])])], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }