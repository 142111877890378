<template>
  <vue-apex-charts
    :options="chartOptions"
    :series="chartData"
    height="210"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line object-curly-newline
import { kFormatter } from '@/@core/utils/filter'
import { getVuetify } from '@core/utils'
import { mdiCurrencyUsd, mdiDotsVertical, mdiTrendingUp } from '@mdi/js'

export default {

  components: {
    VueApexCharts,
  },

  props: {
    data: {
      type: Array,
      default: () => [40, 60, 50, 60, 75, 60, 10],
    },
    labels: {
      type: Array,
      default: () => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },

    formatter: {
      type: Function,
      default: value => `${kFormatter(value, 0)}`,
    },

    // formatter: {
    //   type: Function,
    //   default: value => value,
    // },
  },

  setup(props) {
    const $vuetify = getVuetify()
    const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'

    const chartOptions = {
      colors: [
        customChartColor,
        customChartColor,
        customChartColor,
        customChartColor,
        customChartColor,
        customChartColor,
        customChartColor,
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false,
        },
        offsetX: -15,
      },
      plotOptions: {
        bar: {
          columnWidth: '40%',
          distributed: true,
          borderRadius: 8,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: props.labels,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          show: true,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        tickAmount: 4,
        labels: {
          offsetY: 3,
          formatter: props.formatter,
        },
      },
      stroke: {
        width: [2, 2],
      },
      grid: {
        strokeDashArray: 12,
        padding: {
          right: 0,
        },
      },
    }

    const chartData = [
      {
        data: props.data,
      },
    ]

    return {
      chartOptions,
      chartData,

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>

<style scoped>
.title {
  flex-wrap: nowrap;
}
</style>
