<template>
  <v-form ref="formElem">
    <v-card>
      <v-card-text>
        <h2 class="mb-4">
          Chart Display
        </h2>

        <v-text-field
          v-model="form.nicename"
          label="Chart Title"
          outlined
          :rules="[required]"
        ></v-text-field>

        <v-select
          v-model="form.cols"
          label="Display Col span"
          :items="colsOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-text-field
          v-model="form.sort"
          label="Sort (asc)"
          outlined
        ></v-text-field>

        <!-- <v-select
          v-model="form.formatter"
          :label="`Value Format (No. / $ ...)`"
          :items="formatterOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select> -->

        <v-divider class="mb-4" />

        <h2 class="mb-4">
          Resource Control
        </h2>

        <v-select
          v-model="form.route"
          label="Resource route"
          :items="routeOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <v-select
          v-model="form.type"
          label="Type"
          :items="typeOptions"
          item-text="title"
          item-value="value"
          outlined
        ></v-select>

        <h2 class="mb-4">
          Optional Filters
        </h2>

        <v-autocomplete
          v-model="form.query.distributor_id"
          label="Distributor (null = ALL)"
          :items="distributorOptions"
          item-text="title"
          item-value="value"
          outlined
          clearable
        ></v-autocomplete>

        <template v-if="form.type === 'interval-analysis'">
          <v-divider class="mb-4" />

          <h2 class="mb-4">
            Record Interval Range
          </h2>

          <div class="d-flex">
            <v-select
              v-model="form.query.interval_count"
              :label="`(make stat per ${ form.query.interval_count } ${form.query.interval_unit})`"
              :items="countOptions"
              item-text="title"
              item-value="value"
              outlined
            ></v-select>

            <v-select
              v-model="form.query.interval_unit"
              class="ml-2"
              :label="`Record Unit`"
              :items="unitOptions"
              item-text="title"
              item-value="value"
              outlined
            ></v-select>
          </div>

          <v-switch
            v-model="form.query.cumulative"
            hide-details
            class="mb-4"
            label="Cumulative"
          ></v-switch>
        </template>

        <h2 class="mb-4">
          Chart From-To Date control
        </h2>

        <div class="d-flex">
          <v-select
            v-model="form.options.latest_records_count"
            :label="`Show the last (${form.options.latest_records_count}) ${form.query.interval_unit}`"
            :items="latestRecordsCountOptions"
            item-text="title"
            item-value="value"
            outlined
          ></v-select>

          <v-select
            v-if="form.type === 'rank-analysis'"
            v-model="form.query.interval_unit"
            class="ml-2"
            :label="`Show the last ${form.options.latest_records_count} (${form.query.interval_unit})`"
            :items="unitOptions"
            item-text="title"
            item-value="value"
            outlined
          ></v-select>
        </div>

        <!--
        <div class="demo-space-x">
          Do you want to customDates custom start date of the first record?
          <v-switch
            v-model="form.options.is_custom_dates"
            color="primary"
            :label="form.options.is_custom_dates ? 'Yes, use a custom Start Date' : '(Suggested) No, always show me the latest records until today'"
          ></v-switch>
        </div>

          <template v-if="form.options.is_custom_dates">
          <v-date-picker
            v-model="form.query.from_date"
          />

          <v-date-picker
            v-model="form.query.to_date"
          />
        </template> -->
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Update
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { useDistributor } from '@/composables'
import appStore from '@/modules/app/store'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const { resource } = props

    const initialForm = {
      nicename: resource.nicename,
      sort: resource.sort,
      cols: resource.cols,
      formatter: resource.formatter,
      route: resource.route,
      type: resource.type,
      filter: resource.filter,
      query: resource.query,
      options: resource.options,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const { info } = appStore.state
    const { currency_symbol } = info

    const formatterOptions = [
      { title: 'Default (count No.)', value: 'default' },
      { title: currency_symbol, value: 'dollar' },
    ]

    const countOptions = [
      { title: '1 (suggested)', value: 1 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
      { title: '6', value: 6 },
      { title: '7', value: 7 },
      { title: '14', value: 14 },
      { title: '30', value: 30 },
    ]

    const latestRecordsCountOptions = [
      { title: '1 (suggested)', value: 1 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
      { title: '4', value: 4 },
      { title: '5', value: 5 },
      { title: '6', value: 6 },
      { title: '7', value: 7 },
      { title: '8', value: 8 },
      { title: '9', value: 9 },
      { title: '10', value: 10 },
      { title: '12', value: 12 },
      { title: '14', value: 14 },
      { title: '30', value: 30 },
      { title: '45', value: 45 },
      { title: '60', value: 60 },
    ]

    const unitOptions = [
      { title: 'Day (Suggested)', value: 'day' },
      { title: 'Week', value: 'week' },
      { title: 'Month', value: 'month' },
      { title: 'Year', value: 'year' },
    ]

    const colsOptions = [
      { title: '3', value: 3 },
      { title: '4 (1/3)', value: 4 },
      { title: '5', value: 5 },
      { title: '6 (half)', value: 6 },
      { title: '7', value: 7 },
      { title: '8', value: 8 },
      { title: '9', value: 9 },
      { title: '10', value: 10 },
      { title: '11', value: 11 },
      { title: '12 (full width)', value: 12 },
    ]

    const routeOptions = [
      { title: 'User Activation', value: 'user-activation' },
      { title: 'Order Created', value: 'order-creation' },
      { title: 'Order sales', value: 'order-sales' },
      { title: 'Top Products', value: 'product-ordered' },
      { title: 'Top Categories', value: 'category-ordered' },
    ]

    const typeOptions = [
      { title: 'Date Range', value: 'interval-analysis' },
      { title: 'Ranking', value: 'rank-analysis' },
    ]

    const { distributorOptions } = useDistributor()

    const validate = () => {
      const { query } = form.value

      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      formatterOptions,
      countOptions,
      latestRecordsCountOptions,
      unitOptions,

      colsOptions,
      routeOptions,
      typeOptions,

      distributorOptions,

      validate,
      required,
    }
  },
}
</script>
