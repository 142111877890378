<template>
  <div>
    <v-card>
      <v-card-title class="align-start title">
        <div>
          {{ currentChart.nicename }}
          <v-chip v-if="currentDistributor">
            {{ currentDistributor.nicename }}
          </v-chip>
        </div>

        <v-spacer></v-spacer>

        <v-btn
          icon
          small
          class="mt-n2 me-n3"
          @click="managing = true"
        >
          <v-icon size="22">
            {{ icons.mdiDotsVertical }}
          </v-icon>
        </v-btn>

        <ChartOptionAside
          v-if="managing"
          :active="true"
          :resource="currentChart"
          @aside="bool => { if (!bool) { managing = false } }"
          @submit="handleOptionUpdate"
          @remove="$emit('remove')"
        />
      </v-card-title>

      <v-card-text v-if="results">
        <span
          v-for="[key, value] in Object.entries(results.analysis)"
          :key="key"
          class="mr-4"
        >
          {{ key }}: {{ formatByKey(value, key) }}
        </span>

        <BarChart
          v-if="data.length && currentChart.type === 'interval-analysis'"
          :data="data"
          :labels="labels"
          :formatter="formatter"
        >
        </BarChart>

        <TableChart
          v-if="data.length && currentChart.type === 'rank-analysis'"
          :data="data"
          :labels="labels"
          :formatter="formatter"
        >
        </TableChart>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { money } from '@/@core/utils/filter'
import distributorStore from '@/modules/distributor/store'
import { t } from '@/plugins/i18n'
import { mdiDotsVertical } from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import moment from 'moment'
import useStatistics from '../useStatistics'
import BarChart from './BarChart.vue'
import ChartOptionAside from './ChartOptionAside.vue'
import TableChart from './TableChart.vue'

const defaultCharts = {
  route: 'order-items',
  formatter: 'default',
  nicename: 'Default Title',
  type: 'interval-analysis',
  sort: 50,
  query: {
    interval_count: 1,
    interval_unit: 'day',
  },
}

export default {
  components: { BarChart, TableChart, ChartOptionAside },

  props: {
    index: {
      type: Number,
      default: 0,
    },
    chart: {
      type: Object,
      default: () => defaultCharts,
    },
  },

  setup(props, { emit }) {
    const managing = ref(false)
    const results = ref(null)
    const currentChart = computed(() => ({
      ...defaultCharts,
      ...props.chart,
      func: {
        ...(props.chart.func || {}),
      },
      query: {
        ...defaultCharts.query,
        ...(props.chart.query || {}),
      },
    }))

    const currentDistributor = computed(() => distributorStore.state.allDistributors.find(m => m.id === currentChart.value.query.distributor_id))

    const parsedfromDate = computed(() => {
      const { interval_count, interval_unit, from_date } = currentChart.value.query

      const latestCount = currentChart.value.options.latest_records_count || 7

      const fromDate = from_date || moment().subtract(latestCount, interval_unit)

      return moment(fromDate).startOf(interval_unit).format()
    })

    const parsedToDate = computed(() => {
      const { interval_count, interval_unit, to_date } = currentChart.value.query

      const toDate = to_date || moment().subtract(1, interval_unit)

      return moment(toDate).endOf(interval_unit).format()
    })

    const d = date => moment(date).format('YYYY-MM-DD')
    const sd = date => moment(date).format('MM-DD')

    const data = computed(() => (results.value ? results.value.statistics.map(r => r.value) : []))

    const labels = computed(() => (results.value ? results.value.statistics.map(r => (r.data ? t(r.data.title || r.data.name) : `${sd(r.to_date)}`)) : []))

    // const { info } = appStore.state
    // const { currency_symbol } = info

    const formats = {
      default: value => value,

    //   dollar: value => `${currency_symbol}${value}`,
    }

    const formatter = computed(() => formats[props.chart.formatter] || formats.default)

    const fromDate = computed(() => d(currentChart.value.query.from_date))

    const toDate = computed(() => d(currentChart.value.query.to_date))

    const handleOptionUpdate = form => {
      console.log('form', form)
      managing.value = false
      emit('submit', form)
    }

    const formatByKey = (value, key) => {
      let formatted = value
      switch (key) {
        case 'total':
          formatted = money(value)
          break

        case 'subtotal':
          formatted = money(value)
          break

        default:
          break
      }

      return formatted
    }

    onMounted(() => {
      const statistics = useStatistics()

      statistics.handle({
        route: `${currentChart.value.type}/${currentChart.value.route}`,
        query: {
          ...currentChart.value.query,
          from_date: parsedfromDate.value,
          to_date: parsedToDate.value,
        },

      }).then(res => {
        results.value = res.data.data
      })
    })

    return {
      t,
      managing,
      currentChart,
      currentDistributor,

      results,

      data,
      labels,

      formatByKey,
      formats,
      formatter,

      fromDate,
      toDate,

      handleOptionUpdate,
      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
