var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('v-card', [_c('v-card-text', [_c('h2', {
    staticClass: "mb-4"
  }, [_vm._v(" Chart Display ")]), _c('v-text-field', {
    attrs: {
      "label": "Chart Title",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.nicename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nicename", $$v);
      },
      expression: "form.nicename"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Display Col span",
      "items": _vm.colsOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.cols,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cols", $$v);
      },
      expression: "form.cols"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Sort (asc)",
      "outlined": ""
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", $$v);
      },
      expression: "form.sort"
    }
  }), _c('v-divider', {
    staticClass: "mb-4"
  }), _c('h2', {
    staticClass: "mb-4"
  }, [_vm._v(" Resource Control ")]), _c('v-select', {
    attrs: {
      "label": "Resource route",
      "items": _vm.routeOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.route,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "route", $$v);
      },
      expression: "form.route"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Type",
      "items": _vm.typeOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _c('h2', {
    staticClass: "mb-4"
  }, [_vm._v(" Optional Filters ")]), _c('v-autocomplete', {
    attrs: {
      "label": "Distributor (null = ALL)",
      "items": _vm.distributorOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.query.distributor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form.query, "distributor_id", $$v);
      },
      expression: "form.query.distributor_id"
    }
  }), _vm.form.type === 'interval-analysis' ? [_c('v-divider', {
    staticClass: "mb-4"
  }), _c('h2', {
    staticClass: "mb-4"
  }, [_vm._v(" Record Interval Range ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-select', {
    attrs: {
      "label": "(make stat per ".concat(_vm.form.query.interval_count, " ").concat(_vm.form.query.interval_unit, ")"),
      "items": _vm.countOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.query.interval_count,
      callback: function callback($$v) {
        _vm.$set(_vm.form.query, "interval_count", $$v);
      },
      expression: "form.query.interval_count"
    }
  }), _c('v-select', {
    staticClass: "ml-2",
    attrs: {
      "label": "Record Unit",
      "items": _vm.unitOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.query.interval_unit,
      callback: function callback($$v) {
        _vm.$set(_vm.form.query, "interval_unit", $$v);
      },
      expression: "form.query.interval_unit"
    }
  })], 1), _c('v-switch', {
    staticClass: "mb-4",
    attrs: {
      "hide-details": "",
      "label": "Cumulative"
    },
    model: {
      value: _vm.form.query.cumulative,
      callback: function callback($$v) {
        _vm.$set(_vm.form.query, "cumulative", $$v);
      },
      expression: "form.query.cumulative"
    }
  })] : _vm._e(), _c('h2', {
    staticClass: "mb-4"
  }, [_vm._v(" Chart From-To Date control ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('v-select', {
    attrs: {
      "label": "Show the last (".concat(_vm.form.options.latest_records_count, ") ").concat(_vm.form.query.interval_unit),
      "items": _vm.latestRecordsCountOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.options.latest_records_count,
      callback: function callback($$v) {
        _vm.$set(_vm.form.options, "latest_records_count", $$v);
      },
      expression: "form.options.latest_records_count"
    }
  }), _vm.form.type === 'rank-analysis' ? _c('v-select', {
    staticClass: "ml-2",
    attrs: {
      "label": "Show the last ".concat(_vm.form.options.latest_records_count, " (").concat(_vm.form.query.interval_unit, ")"),
      "items": _vm.unitOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.form.query.interval_unit,
      callback: function callback($$v) {
        _vm.$set(_vm.form.query, "interval_unit", $$v);
      },
      expression: "form.query.interval_unit"
    }
  }) : _vm._e()], 1)], 2), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Update ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }