var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.labels, function (label, index) {
    return _c('v-row', {
      key: label
    }, [_c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_vm._v(" " + _vm._s(label) + " ")]), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.data[index]) + " ")])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }