import { handleStatistics } from '@/api/statistics'

export default function useStatistics() {
  // const aggregateOrderItems = () => [1, 2, 3, 4, 5, 6, 7]
  // const handle = () => [1, 2, 3, 4, 5, 6, 7]
  const handle = ({ route, query }) => handleStatistics(route, { ...query })

  return {
    handle,

    // aggregateOrderItems,
  }
}
