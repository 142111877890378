<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <chart-option-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />

    <p class="text-center">
      <a
        class="text--red"
        @click="$emit('remove')"
      >Remove Chart</a>
    </p>
  </v-navigation-drawer>
</template>

<script>

// import { storeChartOption, updateChartOption } from '@api/common'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ChartOptionForm from './ChartOptionForm.vue'

export default {
  components: { ChartOptionForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true

      emit('submit', form)

      // const request = props.resource ? updateChartOption(props.resource.id, { ...form }) : storeChartOption({ ...form })
      // await request
      //   .then(async res => {
      //     await store.dispatch('common/fetchAllChartOptions')
      //     emit('changed', res.data.data)
      //   })
      //   .catch(err => {
      //     errors.value = useExtractErrors(err)
      //   })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
